import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_4/slide1';
import Slide2 from 'src/slides/desktop/de/chapter_4/sub_4/slide2';
import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_4/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Roter Terror auf polnischen Gebieten | Schlacht bei Warschau" lang="de" description="Morde, Diebstähle und Vergewaltigungen, begangen durch die Rote Armee um 1920" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Roter Terror auf polnischen Gebieten | Schlacht bei Warschau" lang="de" description="Morde, Diebstähle und Vergewaltigungen, begangen durch die Rote Armee um 1920" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
